import React from "react";
import Layout from "../components/Layout";
import _ from "lodash";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import { Container } from "react-bootstrap";
import remarkGfm from "remark-gfm";
import { useLocalization } from "../hooks";

const DynamicPage = ({ pageContext }) => {
  const { pageData, seoData, layoutProps } = pageContext;
  const { locale: currentLocale } = useLocalization();

  return (
    <Layout
      seo={seoData}
      showCart
      fixedHeader
      showLocationInfo
      {...layoutProps}
    >
      <Container className="mt-5 p-5">
        {!_.isEmpty(pageData[currentLocale].title) && (
          <div className="row pb-4">
            <div className="col-12 h1">{pageData[currentLocale].title}</div>
          </div>
        )}
        {!_.isEmpty(pageData[currentLocale].content) && (
          <>
            <div className="row">
              <div className="col-12">
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw, rehypeSanitize]}
                >
                  {pageData[currentLocale].content}
                </ReactMarkdown>
              </div>
            </div>
          </>
        )}
      </Container>
    </Layout>
  );
};

export default DynamicPage;
